import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

document.addEventListener("DOMContentLoaded", function () {
    var calendarEl = document.getElementById("calendar");
    var calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: "/api/events", // URL untuk mengambil data event
        // dateClick: function (info) {
        //     alert("Date: " + info.dateStr);
        //     // Implementasikan logika untuk menambah event baru
        // },
        eventDidMount: function (info) {
            // Set warna berdasarkan data yang diterima
            if (info.event.extendedProps.color) {
                info.el.style.backgroundColor = info.event.extendedProps.color;
                info.el.style.borderColor = info.event.extendedProps.color;
            }
        },
    });
    calendar.render();
});
